.payslip-manager[data-v-3db4d734] {
  display: flex;
  flex-direction: column;
}
.payslip-manager .title[data-v-3db4d734] {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}
.payslip-manager .payslip-btn[data-v-3db4d734] {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}
.payslip-manager-content[data-v-3db4d734] {
    flex: 1;
}
.payslip-manager[data-v-3db4d734] .row-hover-table {
    cursor: pointer;
}
